import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import {BehaviorSubject, catchError, lastValueFrom, Observable, of} from 'rxjs';
import { map } from "rxjs/operators";
import { CUSTOM_NAME, PROCEDURE16 } from "@consts";
import { RoleId, Rol, StatusRequest } from "@enums";
import moment from "moment";
import { addWorkingDaysToDate, getHolidaysByYears, removeWorkingDays } from "@helpers";

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  RoleIds = RoleId;

  getRequest = new BehaviorSubject(false);
  getRequest$ = this.getRequest.asObservable();

  viewRequest = new BehaviorSubject(false);
  viewRequest$ = this.viewRequest.asObservable();

  AssemblyMinutes = new BehaviorSubject(null);
  assemblyMinutes$ = this.AssemblyMinutes.asObservable();

  ResponseResolution = new BehaviorSubject(null);
  ResponseResolution$ = this.ResponseResolution.asObservable();

  visitScheduleOne = new BehaviorSubject(null);
  visitScheduleOne$ = this.visitScheduleOne.asObservable();

  requestSelected = new BehaviorSubject(null);
  requestSelected$ = this.requestSelected.asObservable();

  verificationMatrixSpe = new BehaviorSubject(null);
  verificationMatrixSpe$ = this.verificationMatrixSpe.asObservable();

  private isUpdateMode = new BehaviorSubject<boolean>(false);
  getIsUpdateMode$ = this.isUpdateMode.asObservable();

  constructor(private http: HttpClient) {
  }

  public activeUpdateRequestMode(action: boolean = false) {
    this.isUpdateMode.next(action);
  }

  /**
   * Obtiene las solicitudes del ciudadano
   * @param idSolicitante
   * @param rol
   */
  getRequestsByIdUser(idSolicitante: string, rol: string): Observable<any> {
    const tramiteId = PROCEDURE16.T16_ID;
    return this.http.get<any>(`${ environment.T16_REQUEST_API_URL }/Request/GetAllRequest/IdUserExternal/${ tramiteId }?IdUserExternal=${ idSolicitante }`)
      .pipe(
        map(requests => this.transformRequestsData(requests, true))
      );
  }

  /**
   * Obtiene las solicitudes para los diferentes funcionarios
   * @param idProcedure
   * @param idRole
   * @param idUserInternal
   * @param idUserRole
   */
  getRequestsByFilters(idProcedure: number, idRole: number, idUserInternal?: string, idUserRole?: string): Observable<any> {

    let params = new HttpParams();
    if (idUserInternal && idUserInternal != '') {
      params = params.set('idUserInternal', idUserInternal)
    }
    if (idUserRole && idUserRole != '') {
      params = params.set('idUserRole', idUserRole)
    }

    if (idRole) {
      const flagUser = this.getCurrentFlagUserInternal(idRole);
      if (flagUser.value) {
        params = params.set('flagUserInternal', flagUser.value)
      }
    }

    return this.http.get<any>(
      `${ environment.T16_VALIDATION_API_URL }/Request/GetAllRequest/${ idProcedure }/${ idRole }/IdUserInternal/IdUserRole/flagUserInternal`,
      { params }
    ).pipe(
      map(requests => this.transformRequestsData(requests))
    );
  }

  /**
   * Obtiene las solicitudes con estado de finalización (finalizado y aprobado, anuladas)
   * @param idProcedure
   * @param roleName
   * @param userId
   */
  getRequestsCompletedyFilters(idProcedure: number, roleName: any, userId: any): Observable<any> {
    let params = new HttpParams();

    switch (roleName.toString()) {
      case Rol.CitizenOrApplicant:
        if (userId && userId != '') {
          params = params.set('IdUserExternal', userId)
        }
        break;
      case Rol.Validator:
        if (userId && userId != '') {
          params = params.set('IdUserInternal', userId)
        }
        break;
      case Rol.Coordinator:
        if (userId && userId != '') {
          params = params.set('IdUserCoordinator', userId)
        }
        break;
      case Rol.VicePrincipal:
        if (userId && userId != '') {
          params = params.set('idUserSubdirector', userId)
        }
        break;
      default:
        break;
    }

    return this.http.get<any>(
      `${ environment.T16_VALIDATION_API_URL }/Request/GetAllRequestCompleted/${ idProcedure }`,
      { params }
    ).pipe(
      map(requests => this.transformRequestsData(requests))
    );
  }

  /**
   * Obtiene la matriz de verificación de una especialidad y tramite
   * @param requestId
   * @param idEspecialidad
   */
  getMatrixByRequestIdAndSpecialty(requestId: number, idEspecialidad: string,idspecialist: string): Observable<any> {
    return this.http.get<any>(`${ environment.T16_VALIDATION_API_URL }/Request/GetVerificationMatrix/${ requestId }/${ idEspecialidad }/${ idspecialist }`)
  }

  /**
   * Actualizar el estado de las solicitudes a Solicitud en Desistimiento por el sistema "Automaticamente"
   */
  updateRequestWithdrawal(): Observable<any> {
    return this.http.get<any>(`${ environment.T16_REQUEST_API_URL }/Request/UpdateRequestWithdrawal`)
  }

  /**
   * Obtiene los especialistas asignados a la solicitud
   * @param requestId
   */
  getSpecialistsByRequestId(requestId: number): Observable<any> {
    return this.http.get<any>(`${ environment.T16_VALIDATION_API_URL }/Request/GetRequestSpecialist/${ requestId }/IdUserRole`)
  }

  /**
   * Registra el historial de cambios de una solicitud
   * @param data data de la auditoria
   */
  insertAuditory(data: any) {
    return this.http.post<any>(`${ environment.T16_REQUEST_API_URL }/Auditory/insertAuditory`, data);
  }

  /**
   * Obtiene el formato de notificacion
   * @param IdStateRequest  ID del estado de la solicitud
   * @param IdProcedure  ID del tramite
   */
  getFormats(IdStateRequest : number, IdProcedure : any): Observable<any> {
    return this.http.get<any>(`${ environment.T16_VALIDATION_API_URL }/Document/GetFormats/${ IdStateRequest }/${ IdProcedure }`);
  }

  /**
   * Obtiene el historico de notificaciones
   * @param idRequest
   */
  getNotificationsHistory(idRequest: string): Observable<any[]> {
    return this.http.get<any[]>(`${ environment.T16_VALIDATION_API_URL }/Email/NotificationHistory/${ idRequest }`);
  }

  /**
   * Obtiene los dias habiles pendientes
   * @param id
   */
  getRemainingdayslista(fechas:any ) : Observable<any> {
    return this.http.post(`${environment.TRAMITE_SHARED_URL }/v1/Holiday/CalculateRemainingDaysList`,fechas);
  }

  async transformRequestsData(requests: any, isCitizen: boolean = false) {
    let listasolicitudes = [];

    let actualday:Date = new Date(Date.now());



    requests.forEach(solicitud => {
      /**
       * calcula si ya ha sobrepasado el tiempo de expiracion, de ser asi usa la fecha de la solcitud, sino usa la fecha
       * del ultimo cambio de estado
       */

      let expirationtime:Date= new Date(solicitud.expirationTime)

      if(expirationtime<actualday)
      {
        listasolicitudes.push(solicitud.dateRequest.substring(0, 10) + "");
      }
      else {
        if(solicitud.dateResource!=null)
        {
          listasolicitudes.push(solicitud.dateResource.substring(0, 10) + "");
        }
        else {
          listasolicitudes.push(solicitud.dateRequest.substring(0, 10) + "");
        }

      }

    });

    /**
     * Api que calcular de acuerdo a la fecha ingresada y a los ANS del tramite en bd cuantos dias habiles han transcurrido
     * y cuantos quedan para completar el proceso
     */
    let respuesta = await lastValueFrom(this.getRemainingdayslista(
      {
        initialDates: listasolicitudes,
        idTramite: "E33910E9-4F3F-4075-845C-C7B903420B17"
      }
    ));

    let index=0;

    return (requests || []).map(
      solicitud => {

        //semaforo
        /**
         * se envia los dias restantes para calcular el semaforo
         */
        const attentionTime = this.calculateUsedAttentionTime(solicitud,
          respuesta.data[index].personaJuridica.diasRestantes+"");

        index++;
        return {
          "solicitud": {
            idRequest: solicitud.idRequest,
            "estado": {
              "idEstado": solicitud.idStateRequest,
              "nombre": solicitud?.stateDescription,
              "descripcion": solicitud?.stateDescription
            },
            "numeroRadicado": ((solicitud.idStateRequest != StatusRequest.REQUEST_CREATED && solicitud.idStateRequest != StatusRequest.REQUEST_CANCELED_SYSTEM && solicitud.idStateRequest != StatusRequest.REQUEST_CANCELED) || !isCitizen) ? solicitud?.case_NumberT16 : 'No Disponible',
            "fechaRadicacionSolicitud": solicitud.dateRequest || solicitud.dateResource,
            "idProcedure": solicitud?.idProcedure,
            "idTipoDeTramite": PROCEDURE16.FIRST_TIME,

            "tipoTramite": "Primera Vez",
            "tipoSolicitante": solicitud?.nameApplicant?.personType || 'No Disponible',
            "docSolicitante": solicitud?.nameApplicant?.documentNumber || 'No Disponible',
            nombreTramite: PROCEDURE16.T16_NAME,
            nombreTramiteShort: PROCEDURE16.T16_NAME.slice(0, 50).concat('...'),

            "idSolicitud": solicitud.idRequest,
            "idSolicitante": solicitud.idUserExternal,
            "idUsuarioInterno": solicitud.idUserInternal,
            nombreSolicitante: solicitud?.nameApplicant?.nameApplicant || CUSTOM_NAME(`Usuario ${solicitud.idUserExternal}`),
            extra: solicitud
          },
          "semaforo": attentionTime.percentUsed,
          "diasSemaforo": `${attentionTime.days} (días hábiles)`,
          "businessDays": attentionTime.days,
          "colorTime": attentionTime.extra.colorTime
        }
      }
    );
  }

  /**
   * Calcula el tiempo de atención restante de la solicitud
   * @param request
   * @private
   */
  private calculateUsedAttentionTime(request: any,daysremaining:string): { percentUsed: number, days: number, extra?: any } {
    let percentAttentionTime: number = 0;
    let requestDateOne = moment(request.expirationTime2);
    let daysAdd = PROCEDURE16.ATTENTION_TIME;

    /*
    //quitamos los 30 dias agregados para posteriormente realizar nuevamente el calculo
    let daysToRemove = PROCEDURE16.ATTENTION_TIME;
    const holidaysDeleted = getHolidaysByYears([ requestDateOne.year() - 1, requestDateOne.year() ]);
    const resultWorkingDaysDeleted = removeWorkingDays(requestDateOne.toDate(), daysToRemove, holidaysDeleted);
    let requestDate = moment(resultWorkingDaysDeleted.startDate);
    //==================================================================================

    const holidays = getHolidaysByYears([ requestDate.year(), requestDate.year() + 1 ]);
    const resultWorkingDays = addWorkingDaysToDate(requestDate.toDate(), daysAdd, holidays);
    let daysDiff: number = resultWorkingDays.businessDaysDiff;
if (daysDiff == 0) {
      return {
        percentUsed: percentAttentionTime,
        days: 30,
        extra: { colorTime: this.getColorByAttentionTime(percentAttentionTime) }
      };
    }
    */

    /**
     * en caso de que ya hayan transcurrido mas de los 30 dias permitidos, devuelva directamente que quedan 0 dias
     */
    if(Number.parseInt(daysremaining)<0)
    {
      daysremaining="0";
    }
    /**
     * valida en caso de que queden 30 dias, devuelva directamente esto sin necesidad de hacer una conversion
     */
    let daysDiff: number = Number.parseInt(daysremaining);
    if (daysDiff == 30) {
      return {
        percentUsed: percentAttentionTime,
        days: 30,
        extra: { colorTime: this.getColorByAttentionTime(percentAttentionTime) }
      };
    }
    /**
     * calcula en base a los dias faltantes, cuanto tiempo ha transcurrido para realizar el semaforo
     */

    daysDiff = daysDiff <= daysAdd ? daysDiff : 0;

    /**
     * calcula primero el tiempo (en porcentaje) restante para completar el proceso y luego calcula el tiempo transcurrido
     */
    percentAttentionTime = 100-((100 * daysDiff) / daysAdd);

    percentAttentionTime = (percentAttentionTime <= 0) ? 100 : percentAttentionTime;
    const daysUsed = daysDiff;
    return {
      percentUsed: Math.floor(percentAttentionTime),
      days: (daysUsed <= 0) ? 0 : daysUsed,
      extra: { colorTime: this.getColorByAttentionTime(Math.floor(percentAttentionTime)) }
    };
  }

  /**
   * Retorna el color del semaforo en base al tiempo de atención consumido (porcentaje)
   * @param semaforo
   * @private
   */
  private getColorByAttentionTime(semaforo): string {
    let colorTime = 'circuloverde';
    if (semaforo > 25 && semaforo < 75) colorTime = 'circuloamarillo';
    if (semaforo > 75) colorTime = 'circulorojo';

    return colorTime;
  }

  /**
   * Elimina un especialista de una solicitud
   * @param idRequestSpecialist
   */
  deleteSpecialist(idRequestSpecialist: number) {
    return this.http.delete<any[]>(`${ environment.T16_VALIDATION_API_URL }/Request/DeleteRequestSpecialist/${ idRequestSpecialist }`);
  }

  /**
   * Metodo encargado de retonar el WORD de las resoluciones
   * @param stateId
   * @param data
   */
  buildWordResolution(stateId, data: any) {
    const options = { responseType: 'blob' as 'json' }
    return this.http.put<any>(`${ environment.T16_VALIDATION_API_URL }/Request/ResolutionsWord/${ stateId }`, data, options);
  }

  /**
   * Encargado de eliminar un documento de una solicitud
   * @param idDocument
   */
  deleteDocument(idDocument) {
    return this.http.post<any>(`${ environment.T16_VALIDATION_API_URL }/Document/DeleteDocument/${ idDocument }`, {});
  }

  /**
   * Metodo encargado de crear/actualizar una solicitud de T16
   * @param data
   * @param action
   */
  saveRequest(data, action: string = 'create') {
    return (action == 'create')
      ? this.http.post<any>(`${ environment.T16_REQUEST_API_URL}/Request/InsertRequestT16`, data)
      : this.http.put<any>(`${ environment.T16_REQUEST_API_URL }/Request/UpdateRequestT16`, data)
  }

  /**
   * Metodo encargado de actualizar una solicitud
   * @param data
   */
  updateRequestValidation(data) {
    return this.http.put<any>(`${ environment.T16_VALIDATION_API_URL }/Request/UpdateRequestT16`, data)
  }

  /**
   * Metodo encargado de guardar la asignación de un validador, coordinador, subdirector a la solicitud
   * @param data
   */
  saveUserAssign(data: any) {
    return this.http.put<any>(`${ environment.T16_VALIDATION_API_URL }/Request/UpdateUserInternal`, data);
  }

  /**
   * Obtiene el detalle de una solicitud (ciudadano)
   * @param id
   * @param userId
   */
  getRequestById(id, userId): Observable<any> {
    return this.http.get<any>(`${ environment.T16_REQUEST_API_URL }/Request/GetRequestDetails/${ id }/IdUserExternal?IdUserExternal=${ userId }`);
  }

  /**
   * Obtiene las actas generadas de una solicitud
   * @param id ID de la solicitud
   */
  getRequestActsInfoV2(id): Observable<any> {
    return this.http.get<any[]>(`${ environment.T16_VALIDATION_API_URL }/Request/GetRequestActsA/${ id }`);
  }

  /**
   * Obtiene el detalle de una solicitud (funcionarios)
   * @param idProcedure
   * @param role
   * @param idRequest
   */
  getRequestDetails(idProcedure: number, role: any, idRequest: number): Observable<any> {
    return this.http.get<any>(`${ environment.T16_VALIDATION_API_URL }/Request/GetRequestDetails/${ idRequest }/${ idProcedure }/${ this.RoleIds[role] }/IdUserRole`);
  }

  /**
   * Metodo que permite generar la previsualización de las resoluciones
   * @param stateId
   * @param data
   */
  getPreviewDoc2(stateId, data: any) {
    return this.http.put<any>(`${ environment.T16_VALIDATION_API_URL }/Request/ResolutionsPDF/${ stateId }`, data);
  }
  /**
   * Metodo que permite obtener el token
   * @param stateId
   * @param data
   */
  getTokenRegistro(): Observable<any> {
    return this.http.get<any>(`${ environment.T16_VALIDATION_API_URL_TOKEN}/v1/UserRole/token`);
  }
  /**
   * Obtiene el historico de cambios de una solicitud
   * @param idRequest
   */
  getAuditory(idRequest: string): Observable<any[]> {
    return this.http.get<any[]>(`${ environment.T16_VALIDATION_API_URL }/Request/GetRequestFlowActivity/${ idRequest }`);
  }

  /**
   * Obtiene las versiones de un documento
   * @param idRequest
   * @param idReqDoc
   */
  getVersionsForDocuments(idRequest: number, idReqDoc: any): Observable<any[]> {
    return this.http.get<any[]>(`${ environment.T16_VALIDATION_API_URL }/Request/GetVersionDocument/${ idRequest }/${ idReqDoc }`);
  }
  /**
   * envia el email a traves del backend
   */
  sendEmail(json: any): Observable<any> {
    return this.http.post(`${ environment.T16_VALIDATION_API_URL }/Email/sendEmail`, json, {responseType:'text'});
  }
  /**
   * Obtiene el acta de asamblea vinculado a la entidad
   * @param idEsal
   * @param idProcedure
   * @param idRequest
   */
  getAssemblyActsByEsal(idEsal: number, idProcedure: number, idRequest: number) {
    return this.http.get<any>(`${ environment.T16_REQUEST_API_URL }/Request/GetAssemblyAct/${ idEsal }/${ idProcedure }/${ idRequest }`);
  }

  /**
   * Metodo encargado de retornar las solicitudes para el reporte
   * @param pageIndex
   * @param pageSize
   * @param typeSearch
   * @param filters
   * @param consulta ['', 'descarga']
   */
  getRequestsForReportDashboard(pageIndex: number, pageSize: number, typeSearch: any, filters: any, consulta: string = '') {
    let params = new HttpParams()
      .append('filterType', typeSearch != '0' ? typeSearch : '')
      .append('consulta', `${ consulta }`)
      .append('pagenumber', `${ pageIndex }`)
      .append('pagesize', `${ pageSize }`);
    if (typeSearch != 'fecha') {
      params = params.append('filter', filters?.filter?.toString()?.trim() ?? '')
    }

    if (typeSearch == 'fecha') {
      params = params.append('dateinitial', `${ moment(filters?.startDate).format('YYYY-MM-DD') }`)
        .append('datefinal', `${ moment(filters?.endDate).format('YYYY-MM-DD') }`)
    }

    return this.http.get(`${ environment.T16_VALIDATION_API_URL }/Reports/GetReportEsal`, { params })
      .pipe(
        map((respData: any) => {
          return {
            data: respData.data.map(item => {
              // sol creada de solicitud - sol cancelada por sistema - sol cancelada por usuario ext.
              item.radicado = (item.estado.toLowerCase().includes('creada por usuario') || item.estado.toLowerCase().includes('cancelada por')) ? 'No Disponible' : item.radicado;
              return item;
            }),
            total: respData.count
          }
        }), catchError(() => of({ data: [], total: 10 }))
      )
  }

  /**
   * Metodo que permite identificar que tipo de usuario se asignara a la solicitud (validador, coordinador, subdirector)
   * @param currentRol
   * @private
   */
  private getCurrentFlagUserInternal(currentRol) {
    switch (currentRol.toString()) {
      case RoleId.validador.toString():
        return { value: 1, comp: 'Validador' };
      case RoleId.coordinador.toString():
        return { value: 2, comp: 'Coordinador' };
      case RoleId.subdirector.toString():
        return { value: 3, comp: 'Subdirector' };
      default:
        return { value: null, comp: '' };
    }
  }

  /**
   * Funcion encargada de determinar la fecha de expiración de una solicitud a partir de la creación/actualización de una solicitud
   * @param request
   * @param role
   */
  public calculateExpirationTimeForSave(request: any, role: string) {
    let expirationTime: any;
    switch (Number(request.idStateRequest)) {
      // estados ciudadano
      case StatusRequest.REQUEST_CREATED:
      case StatusRequest.REQUEST_SENDED:
      case StatusRequest.REQUEST_CORRECTED:
      // estados funcionario sds
      case StatusRequest.REQUEST_APPROVED_SIGNED:
      case StatusRequest.REQUEST_INCOMPLETE:
      case StatusRequest.REQUEST_DESIST_APPROVED_SIGNED:
      case StatusRequest.REQUEST_APPROVED_RESOURCE:
      case StatusRequest.REQUEST_APPROVED_ACLARATION_RESOURCE:
      case StatusRequest.REQUEST_APPROVED_REVOCAL_RESOURCE:
        if (role == Rol.Validator && (request.idStateRequest == StatusRequest.REQUEST_SENDED || request.idStateRequest == StatusRequest.REQUEST_CORRECTED)) {
          return request.expirationTime;
        }

        let daysAdd: number = PROCEDURE16.ATTENTION_TIME;
        expirationTime = moment().tz("America/Bogota");
        const holidays = getHolidaysByYears([ expirationTime.year(), expirationTime.year() + 1 ]);
        const resultWorkingDays = addWorkingDaysToDate(expirationTime.toDate(), daysAdd, holidays);
        return moment(resultWorkingDays.lastDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      default:
        return request.expirationTime;
    }
  }
}
